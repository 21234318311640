import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <hr></hr>
    <h2 {...{
      "id": "country-life--farming-season"
    }}>{`Country Life : Farming Season`}</h2>
    <p>{`Farming Season is a game bases on the story of our very successful title, Country Life`}</p>
    <p><img alt="intro" src={require("./public/images/main.png")} /></p>
    <p>{`Country Life is a story of a young farmer boy, Derek, who is in love with the mayor's daugher, Tida. Derek has to prove himself by growing his farming business`}</p>
    <p>{`Farming Season is a Play and Earn game. You will be able to collect NFTs which will affect the game in certain ways. Also, you might earn some by collecting CLC token which is the currency in the game`}</p>
    <p>{`However, this is not a game for investing. Farming Season has its purpose to educate its players about GameFi. First time crypto players are very welcomed and we promise to keep the game simple and very easy to get onboard`}</p>
    <blockquote>
      <p parentName="blockquote">{`As a player in Country Life : Farming Season, you will earn CLC tokens when you complete quests and sell special products.`}</p>
    </blockquote>
    <hr></hr>
    <h2 {...{
      "id": "official-website--social-media"
    }}>{`Official Website & Social Media`}</h2>
    <ul>
      <li parentName="ul">{`Website - `}<a parentName="li" {...{
          "href": "https://www.season.town/"
        }}>{`https://www.season.town/`}</a></li>
      <li parentName="ul">{`Facebook - `}<a parentName="li" {...{
          "href": "https://www.facebook.com/groups/611627083492022"
        }}>{`Farming Season Official Group`}</a></li>
      <li parentName="ul">{`Contact us - `}<a parentName="li" {...{
          "href": "https://www.facebook.com/progaming.co.th"
        }}>{`ProGaming`}</a></li>
      <li parentName="ul">{`Report Issue - `}<a parentName="li" {...{
          "href": "https://share.hsforms.com/16xae8YKwRJ6qA_0BPzc6nQ3tki0"
        }}>{`Report Form`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      